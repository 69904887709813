module.exports.mapEdgesToNodes = (data) => {
	if (!data.edges) return []
	return data.edges.map((edge) => edge.node)
}

module.exports.sitemapSerializer = (edges) => {
	return edges.map((edge) => {
		edge.node.slug = edge.node.slug.current
		edge.node.updated_at = edge.node._updatedAt
		return edge
	})
}
