import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import ProductCard from '../components/product-card'
import SEO from '../components/seo'
import Breadcrumbs from '../components/navigation/breadcrumbs'
import { getManufacturerURL, getSlug } from '../utils/url'
import { mapEdgesToNodes } from '../utils/helpers'

const ManufacturerTemplate = ({ pageContext, data }) => {
	const { title, slug } = pageContext
	const productNodes = mapEdgesToNodes(data.allSanityProduct)

	return (
		<Layout>
			<SEO title={title} />
			<Breadcrumbs url={getManufacturerURL(getSlug(slug))} />
			<h1 className="uk-heading-line">
				<span>{title}</span>
			</h1>
			<div id="products">
				{productNodes.map((node) => (
					<ProductCard key={getSlug(node)} product={node} />
				))}
			</div>
		</Layout>
	)
}

ManufacturerTemplate.propTypes = {
	pageContext: PropTypes.object,
}

export default ManufacturerTemplate

export const query = graphql`
	query ManufacturerProductsData($id: String!) {
		allSanityProduct(
			filter: { manufacturer: { id: { eq: $id } } }
			sort: { fields: _createdAt, order: DESC }
		) {
			edges {
				node {
					...ProductCardDataFragment
				}
			}
		}
	}
`
